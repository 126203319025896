export const getFragment = (key, defaultValue = '') => {
  const fragmentRegExp = new RegExp(`[\\#&]${key}=([^&#]*)`);
  const result = fragmentRegExp.exec(window.location.hash);

  if (result && result.length >= 2) {
    return decodeURIComponent(result[1]);
  }

  return defaultValue;
};

export const getOrganisationFromUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    const splittedUrl = window.location.host.split('.');
    return splittedUrl[0] !== 'www' ? splittedUrl[0] : splittedUrl[1];
  }

  return process.env.VUE_APP_DEV_ORGANISATION;

};
